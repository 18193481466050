// This method ensures the callback runs at some point after the DOM is loaded.  If it is called before the DOM is loaded it creates an event listener, if it is called after it executes the callback immediately.  This can be used with partials that may be included with the initial page load or sometime after the fact, and ensures the code related to those partials will run in the correct context
global.onLoadOrInject = function(callback) {
	if(global.contentLoadEmitted === false)	document.addEventListener("DOMContentLoaded", callback);
	else callback(...contentLoadEmittedArgs);
}

let contentLoadEmittedArgs;

// Tracks if DOMContentLoaded has been emitted
global.contentLoadEmitted = false;

// Sets the flag when DOMContentLoaded is emitted
document.addEventListener("DOMContentLoaded", function(...args) {
	global.contentLoadEmitted = true;
	contentLoadEmittedArgs = args;
});